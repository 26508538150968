// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-page-js": () => import("../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-news-page-js": () => import("../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-responsibility-page-js": () => import("../src/templates/responsibility-page.js" /* webpackChunkName: "component---src-templates-responsibility-page-js" */),
  "component---src-templates-operations-page-js": () => import("../src/templates/operations-page.js" /* webpackChunkName: "component---src-templates-operations-page-js" */),
  "component---src-templates-news-post-js": () => import("../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-pl-page-js": () => import("../src/templates/pl-page.js" /* webpackChunkName: "component---src-templates-pl-page-js" */),
  "component---src-templates-pl-post-js": () => import("../src/templates/pl-post.js" /* webpackChunkName: "component---src-templates-pl-post-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

